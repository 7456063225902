import { Component, Inject, OnInit } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-request-payment',
  templateUrl: './request-payment.component.html',
  styleUrls: ['./request-payment.component.scss']
})
export class RequestPaymentComponent implements OnInit {
  amount: string;
  qrCodeBase64: string;
  pixCopyPaste: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar) {
    this.amount = this.formatCurrency(this.data.amount) || '';
    this.qrCodeBase64 = this.data.qrCodeBase64 || '';
    this.pixCopyPaste = this.data.pixCopyPaste || '';
  }
  ngOnInit(): void {}

  formatCurrency(value: number): string {
    let [integerPart, decimalPart] = value.toFixed(2).split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${integerPart},${decimalPart}`;
  }

  copyPix() {
    navigator.clipboard
      .writeText(this.pixCopyPaste)
      .then(() => {
        this.snackBar.open('Código PIX copiado!', 'Fechar', { duration: 2000 });
      })
      .catch((err) => {
        console.error('Erro ao copiar o código PIX: ', err);
      });
  }
}
