import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { clearFilter, date, repeatQuery } from '../../actions/origin-certificate-query.action';
import { OriginCertificateQuery } from '../../interfaces/OriginCertificateQuery.interface';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import Language from 'src/app/core/interfaces/language.interface';

@Component({
  selector: 'search-origin-certificate',
  templateUrl: './search-origin-certificate.component.html',
  styleUrls: ['./search-origin-certificate.component.scss'],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL']
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class SearchOriginCertificateComponent implements OnInit {
  query$: Observable<OriginCertificateQuery>;
  destroy$ = new Subject();
  language$: Observable<Language>;
  languageCode: string;

  rangeDate = this.fb.group({
    start: [''],
    final: ['']
  });
  certificateNumbers: string[] = [];
  @Output() certificateNum = new EventEmitter<string[]>();
  @Output() invoiceNumber = new EventEmitter<string[]>();
  invoiceNumbers: string[] = [];
  mask: string;

  constructor(
    private readonly originCertificateQueryStore: Store<{
      originCertificateQuery: OriginCertificateQuery;
    }>,
    private readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly langStore: Store<{ language: Language }>,

    private readonly _adapter: DateAdapter<any>,
    private readonly datepipe: DatePipe
  ) {
    this.query$ = originCertificateQueryStore.pipe(select('originCertificateQuery'));
    this.language$ = langStore.pipe(select('language'));
  }

  ngOnInit(): void {
    combineLatest([this.query$, this.language$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([query, lang]: [OriginCertificateQuery, Language]) => {
        if (query) {
          this.certificateNumbers = [...query?.certificateNumber];
          this.invoiceNumbers = [...query?.invoiceNumber];
          this.rangeDate.controls.start.setValue(query?.startDate ? new Date(query?.startDate + 'T00:00:00').toISOString() : '');
          this.rangeDate.controls.final.setValue(query?.finalDate ? new Date(query?.finalDate + 'T00:00:00').toISOString() : '');
          this.rangeDate.updateValueAndValidity();
        }

        if (lang) {
          this.languageCode = lang.code;
          this.setLocaleAndMask(this.languageCode);
        }
      });

    this.language$.pipe(takeUntil(this.destroy$)).subscribe((lang: Language) => {
      if (lang) {
        this.languageCode = lang.code;
        this.setLocaleAndMask(this.languageCode);
      }
    });
  }

  setLocaleAndMask(languageCode: string) {
    if (languageCode === 'en') {
      this._adapter.setLocale('en');
    } else {
      this._adapter.setLocale('pt-BR');
    }
  }

  filterDate() {
    if (
      (this.rangeDate.controls.start.value && this.rangeDate.controls.final.value) ||
      (this.rangeDate.controls.start.value == null && this.rangeDate.controls.final.value == null)
    ) {
      let FinalDate;
      if (this.rangeDate.controls.final.value && this.rangeDate.controls.final.value !== null) {
        const finalDate = new Date(this.rangeDate.controls.final.value);
        const day = finalDate.getDate()?.toString();
        const month = (finalDate.getMonth() + 1)?.toString();
        const year = finalDate.getFullYear()?.toString();
        FinalDate = year + '-' + month + '-' + day;
      }

      let StartDate;
      if (this.rangeDate.controls.start.value && this.rangeDate.controls.start.value !== null) {
        const startDate = new Date(this.rangeDate.controls.start.value);
        const day = startDate.getDate()?.toString();
        const month = (startDate.getMonth() + 1)?.toString();
        const year = startDate.getFullYear()?.toString();
        StartDate = year + '-' + month + '-' + day;
      }

      this.originCertificateQueryStore.dispatch(date({ finalDate: FinalDate, startDate: StartDate }));
    }
  }

  filterByCertificaNumber(certificates: string[]) {
    certificates = certificates.map((x) => x.trim());
    this.certificateNum.emit(certificates);
  }

  filterByInvoiceNumber(invoices: string[]) {
    invoices = invoices.map((x) => x.trim());
    this.invoiceNumber.emit(invoices);
  }

  resetCalendar() {
    this.rangeDate.controls.start.setValue(undefined);
    this.rangeDate.controls.final.setValue(undefined);
  }

  resendQuery() {
    this.originCertificateQueryStore.dispatch(repeatQuery({ repeat: true }));
  }

  hasFilter() {
    return this.certificateNumbers.length > 0 || this.invoiceNumbers.length > 0 || this.rangeDate.controls.start?.value != '' || this.rangeDate.controls.final?.value != '';
  }

  clearFilter() {
    this.originCertificateQueryStore.dispatch(clearFilter());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
