<h2 color="primary" *ngIf="!currentId">
  {{ 'easy-trade.origin-certificate.new-origin-certificate' | translate }}
</h2>

<h2 color="primary" *ngIf="currentId">
  {{ 'easy-trade.origin-certificate.edit-origin-certificate' | translate }}
</h2>
<mat-horizontal-stepper linear="true" class="mat-stepper">
  <mat-step [completed]="validateCompletedFirstStep()" state="description">
    <ng-template matStepLabel
      >{{ 'easy-trade.new-trade.stepper.timeline.invoice' | translate }}
      {{ 'easy-trade.new-trade.stepper.timeline.information' | translate }}
    </ng-template>
    <div class="registration__title">
      <p class="mat-h2" color="primary">
        {{ 'easy-trade.new-trade.stepper.timeline.invoice-information' | translate }}
      </p>
    </div>

    <app-invoice-form
      #invoiceForm
      [type]="invoiceFormTypeEnum.NEW_CERTIFICATE"
      [defaultValue]="invoiceFormCurrentValues"
      (formValue)="setInvoiceInformation($event)"
    ></app-invoice-form>

    <div fxLayout="row" class="button-group" fxLayoutGap="25px" fxLayoutAlign="end end">
      <button mat-raised-button class="button-control btn-cancel" (click)="leaveComponent()">
        {{ 'easy-trade.new-trade.stepper.button.cancel' | translate }}
      </button>
      <button color="accent" mat-raised-button class="button-control" matStepperNext [disabled]="!validateCompletedFirstStep()">
        {{ 'easy-trade.new-trade.stepper.button.next' | translate }}
      </button>
    </div>
  </mat-step>
  <!-- companies-involved -->
  <mat-step [completed]="!penddingCompanies() || canPass" state="business">
    <div class="registration__title">
      <p class="mat-h2" color="primary">
        {{ 'settings.companies.importer' | translate }}
      </p>
    </div>

    <form class="companiesContainer" [formGroup]="companiesFormGroup">
      <ng-template matStepLabel>{{ 'easy-trade.new-trade.stepper.timeline.companies-involved' | translate }}</ng-template>
      <div [ngClass]="{ 'container-companies-flex': !checkOpenBox() }">
        <div class="container-importer" *ngIf="OpenshowImporterContainer()">
          <div class="[container-company]" *ngIf="!showImporterSelection">
            <div *ngIf="!checkCompaniesFormGroupImporter()" class="box-company" (click)="OpenshowImporterSelection()">
              <p class="box-company-title">
                {{ 'settings.companies.importer' | translate }}
              </p>
              <span class="box-company-name">{{ this.companiesFormGroup.value?.importer?.name | dashIfNull }}</span>
              <mat-select-country class="box-company-select-country" [value]="findCountryByIdImporter()" appearance="standard" label=" " readonly="true"> </mat-select-country>
              <span class="box-company-street">{{ this.companiesFormGroup.value?.importer?.street | dashIfNull }}</span>
              <span class="box-company-box">{{ 'settings.companies.poBox' | translate }}: {{ this.companiesFormGroup.value?.importer?.poBox | dashIfNull }}</span>
            </div>
            <div *ngIf="checkCompaniesFormGroupImporter()" (click)="OpenshowImporterSelection()" class="box-company">
              <p class="unselected-company">{{ 'settings.companies.importer' | translate }}</p>
              <p class="select-an">{{ 'settings.companies.select-an-importer' | translate }}</p>
            </div>
          </div>
          <app-company-management
            *ngIf="showImporterSelection"
            [typeCompany]="CompanyTypeEnum.IMPORTER"
            [countries]="countries"
            (closeTableEvent)="closeTableCompanyEvent($event)"
            (companySelected)="companySelectedEvent($event)"
          ></app-company-management>
        </div>
        <div class="contaianer-advance-button" *ngIf="checkOpemCompaniesInvolvedAdvance()">
          <mat-icon (click)="openCompaniesInvolvedAdvance()">add_circle</mat-icon>
          <p class="advance-button" (click)="openCompaniesInvolvedAdvance()">
            {{ 'easy-trade.new-trade.stepper.companies-involved.advance-button' | translate | uppercase }}
          </p>
        </div>

        <div class="container-consignee" *ngIf="OpenshowConsigneeContainer()">
          <div class="container-company" *ngIf="!showConsigneeSelection">
            <div *ngIf="!checkCompaniesFormGroupConsignee()" class="box-company" (click)="OpenshowConsigneeSelection()">
              <p class="box-company-title">
                {{ 'easy-trade.new-trade.stepper.companies-involved.consignee' | translate }}
              </p>
              <span class="box-company-name">{{ companiesFormGroup.value?.consignee?.name | dashIfNull }}</span>
              <mat-select-country class="box-company-select-country" [value]="findCountryByIdConsignee()" appearance="standard" label=" " readonly="true"> </mat-select-country>
              <span class="box-company-street">{{ companiesFormGroup.value?.consignee?.street | dashIfNull }}</span>
              <span class="box-company-box">{{ 'settings.companies.poBox' | translate }}: {{ companiesFormGroup.value?.consignee?.poBox | dashIfNull }}</span>
            </div>
            <div *ngIf="checkCompaniesFormGroupConsignee()" (click)="OpenshowConsigneeSelection()" class="box-company">
              <p class="unselected-company">{{ 'easy-trade.new-trade.stepper.companies-involved.consignee' | translate }}</p>
              <p class="select-an">{{ 'settings.companies.select-an-consignee' | translate }}</p>
            </div>
          </div>
          <app-company-management
            *ngIf="showConsigneeSelection"
            [typeCompany]="CompanyTypeEnum.CONSIGNEE"
            [countries]="countries"
            (closeTableEvent)="closeTableCompanyEvent($event)"
            (companySelected)="companySelectedEvent($event)"
          ></app-company-management>
        </div>

        <div class="container-buyer" *ngIf="OpenshowBuyerContainer()">
          <div class="container-company" *ngIf="!showBuyerSelection">
            <div *ngIf="!checkCompaniesFormGroupBuyer()" class="box-company" (click)="OpenshowBuyerSelection()">
              <p class="box-company-title">
                {{ 'easy-trade.new-trade.stepper.companies-involved.buyer' | translate }}
              </p>
              <span class="box-company-name">{{ this.companiesFormGroup.value.buyer?.name | dashIfNull }}</span>
              <mat-select-country class="box-company-select-country" [value]="findCountryByIdBuyer()" appearance="standard" label=" " readonly="true"> </mat-select-country>
              <span class="box-company-street">{{ this.companiesFormGroup.value.buyer?.street | dashIfNull }}</span>
              <span class="box-company-box">{{ 'settings.companies.poBox' | translate }}: {{ this.companiesFormGroup.value.buyer?.poBox | dashIfNull }}</span>
            </div>
            <div *ngIf="checkCompaniesFormGroupBuyer()" (click)="OpenshowBuyerSelection()" class="box-company">
              <p class="unselected-company">{{ 'easy-trade.new-trade.stepper.companies-involved.buyer' | translate }}</p>
              <p class="select-an">{{ 'settings.companies.select-an-buyer' | translate }}</p>
            </div>
          </div>
          <app-company-management
            *ngIf="showBuyerSelection"
            [typeCompany]="CompanyTypeEnum.BUYER"
            [countries]="countries"
            (closeTableEvent)="closeTableCompanyEvent($event)"
            (companySelected)="companySelectedEvent($event)"
          ></app-company-management>
        </div>
        <div class="contaianer-advance-back" *ngIf="checkIsOpenCompaniesInvolvedAdvance()">
          <mat-icon (click)="cloaseCompaniesInvolvedAadvance()">arrow_back</mat-icon>
        </div>
      </div>
    </form>

    <div fxLayout="row" class="button-group" fxLayoutGap="25px" fxLayoutAlign="end end">
      <button mat-raised-button class="button-control btn-cancel" (click)="leaveComponent()">
        {{ 'easy-trade.new-trade.stepper.button.cancel' | translate }}
      </button>
      <button mat-raised-button class="button-control btn-cancel" matStepperPrevious>
        {{ 'easy-trade.new-trade.stepper.button.back' | translate }}
      </button>

      <button mat-raised-button class="button-control" color="accent" matStepperNext [disabled]="penddingCompanies()">
        {{ 'easy-trade.new-trade.stepper.button.next' | translate }}
      </button>
    </div>
  </mat-step>
  <!-- shipment-information -->
  <mat-step [completed]="(shipmentFormGroup.valid && !penddingTransshipment()) || canPass" state="directions_boat">
    <form [formGroup]="shipmentFormGroup" class="fouth-form">
      <ng-template matStepLabel>{{ 'easy-trade.new-trade.stepper.timeline.shipment-information' | translate }}</ng-template>
      <div fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start" class="registration__title">
        <div fxFlex="100">
          <p class="mat-h2" color="primary">
            {{ 'easy-trade.new-trade.stepper.timeline.shipment-information' | translate }}
          </p>
        </div>
      </div>
      <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="20" class="registration--shipment-information__slide-toggle">
          <mat-button-toggle-group class="toggle-shipment-group" formControlName="shipmentType">
            <mat-button-toggle class="select-shippment-button" *ngFor="let transship of transhipments" [value]="transship">{{
              'easy-trade.new-trade.stepper.shipment-information.' + transship.toLowerCase() | translate
            }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div fxFlex="80" fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="start">
          <mat-select-country
            [placeHolder]="'easy-trade.new-trade.stepper.details-requirements.destination-country' | translate"
            [label]="'easy-trade.new-trade.stepper.details-requirements.destination-country' | translate"
            fxFlex.lt-md="100"
            [required]="true"
            (onCountrySelected)="onCountrySelected($event)"
            appearance="outline"
            [countries]="predefinedDestinationCountries"
            *ngIf="predefinedDestinationCountries.length > 0"
            [formControl]="shipmentFormGroup.controls['destinationCountry']"
            [value]="destinationCountry"
          >
          </mat-select-country>

          <mat-form-field fxFlex="15" color="accent" appearance="outline">
            <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.awbNumber' | translate }}</mat-label>
            <input matInput formControlName="awbNumber" autocomplete="off" (keypress)="keyPressAlphaNumeric($event)" />
          </mat-form-field>

          <mat-form-field fxFlex="15" (click)="shippingDate.open()" color="accent" appearance="outline">
            <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.shipping-date' | translate }}</mat-label>
            <input
              matInput
              readonly
              required
              [matDatepicker]="shippingDate"
              formControlName="shippingDate"
              [placeholder]="'easy-trade.new-trade.stepper.shipment-information.shipping-date-placeholder' | translate"
            />
            <mat-datepicker-toggle matSuffix [for]="shippingDate"></mat-datepicker-toggle>
            <mat-datepicker #shippingDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex="15" color="accent" appearance="outline">
            <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.imo_number' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="imo_number" OnlyNumber="true" min="0" />
          </mat-form-field>

          <mat-form-field *ngIf="shipmentFormGroup.value.shipmentType == 'Ship'" fxFlex="15" color="accent" appearance="outline">
            <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.vessel-name' | translate }}</mat-label>
            <input matInput formControlName="vesselName" autocomplete="off" type="text" />
          </mat-form-field>

          <mat-form-field *ngIf="destinationCountry?.alpha3Code == 'EGY'" fxFlex="15" color="accent" appearance="outline">
            <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.acidNumber' | translate }}</mat-label>
            <input matInput formControlName="acidNumber" autocomplete="off" type="text" (keyup)="checkNumberFieldLength()" />
          </mat-form-field>
        </div>
      </div>
      <div fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start"></div>

      <app-transshipment-flow
        fxFill
        [isNewTrade]="true"
        [shipmentMethod]="shipmentFormGroup.value.shipmentType"
        [CertificateProcessing]="!currentId ? 0 : 1"
        [defaultTranshipments]="transshipmentFlow"
        (transshipmentFlow)="setTransshipmentFlow($event)"
      ></app-transshipment-flow>
    </form>

    <div fxLayout="row" class="button-group" fxLayoutGap="25px" fxLayoutAlign="end end">
      <button mat-raised-button class="button-control btn-cancel" (click)="leaveComponent()">
        {{ 'easy-trade.new-trade.stepper.button.cancel' | translate }}
      </button>

      <button mat-raised-button class="button-control btn-cancel" matStepperPrevious>
        {{ 'easy-trade.new-trade.stepper.button.back' | translate }}
      </button>

      <button color="accent" mat-raised-button class="button-control" matStepperNext [disabled]="!shipmentFormGroup.valid || penddingTransshipment()">
        {{ 'easy-trade.new-trade.stepper.button.next' | translate }}
      </button>
    </div>
  </mat-step>
  <!-- product-information-ncm -->
  <mat-step [completed]="templateData?.products?.length > 0 || canPass" state="sell">
    <ng-template matStepLabel>{{ 'easy-trade.new-trade.stepper.timeline.product-information-ncm' | translate }}</ng-template>
    <app-exportation-products [useProductValue]="true" [canEdit]="canEditProducts" [isOriginCertificate]="true" [productsGrid]="productsGrid" (productList)="productList($event)">
    </app-exportation-products>
    <div fxLayout="row" class="button-group" fxLayoutAlign="end end" fxLayoutGap="30px">
      <button mat-raised-button class="button-control btn-cancel" (click)="leaveComponent()">
        {{ 'easy-trade.new-trade.stepper.button.cancel' | translate }}
      </button>
      <button mat-raised-button class="button-control btn-cancel" matStepperPrevious>
        {{ 'easy-trade.new-trade.stepper.button.back' | translate }}
      </button>
      <button
        mat-raised-button
        color="accent"
        type="button"
        class="button-control next-product"
        [disabled]="!templateData?.products || templateData?.products?.length === 0"
        matStepperNext
      >
        <span
          [matTooltip]="'easy-trade.new-trade.stepper.timeline.pending-product-label' | translate"
          [matTooltipDisabled]="templateData?.products?.length > 0"
          class="next-anchor"
          >{{ 'easy-trade.new-trade.stepper.button.next' | translate }}</span
        >
      </button>
    </div>
  </mat-step>

  <mat-step class="registration--finish" state="check">
    <ng-template matStepLabel>
      {{ 'easy-trade.new-trade.stepper.timeline.complete' | translate }}
    </ng-template>

    <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="12px" class="registration__title">
      <p class="mat-h1" color="primary">
        {{ 'easy-trade.origin-certificate.finish-title' | translate }}
      </p>
      <p class="mat-h2 margin-custom" color="primary">
        {{ 'easy-trade.origin-certificate.finish-subtitle' | translate }}
      </p>
      <p class="mat-h3 h3-custom">
        {{ 'easy-trade.origin-certificate.finish-line-1' | translate }}
      </p>
      <p class="custom-finish">
        {{ 'easy-trade.origin-certificate.finish-line-2' | translate }}
      </p>
    </div>

    <mat-checkbox class="confirm-transaction-checkbox" #confirmInformation="matCheckbox">
      {{ 'easy-trade.new-trade.stepper.finish.checkbox' | translate }}
    </mat-checkbox>

    <div fxLayout="row" class="button-group" fxLayoutGap="25px" fxLayoutAlign="end end">
      <button mat-raised-button class="button-control btn-cancel" (click)="leaveComponent()">
        {{ 'easy-trade.new-trade.stepper.button.cancel' | translate }}
      </button>

      <button mat-raised-button class="button-control btn-cancel" matStepperPrevious>
        {{ 'easy-trade.new-trade.stepper.button.back' | translate }}
      </button>

      <!-- <button *ngIf="!currentId" color="primary" mat-raised-button class="button-control"
                (click)="submitAndCreateExportation()">
                {{ "easy-trade.new-trade.stepper.button.send-and-export" | translate | uppercase }}
            </button> -->

      <button *ngIf="!currentId" color="accent" mat-raised-button class="button-control" [disabled]="!confirmInformation.checked" (click)="submitAndRediirectoToList(false)">
        {{ 'easy-trade.new-trade.stepper.button.send' | translate }}
      </button>

      <button *ngIf="currentId" color="accent" mat-raised-button class="button-control" [disabled]="!confirmInformation.checked" (click)="submitAndRediirectoToList(true)">
        {{ 'easy-trade.new-trade.stepper.button.update' | translate }}
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
