<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center ">
      <span></span>
      <p>{{ 'shared.confirm-dialog.data-lost' | translate }}</p>

      <button mat-icon-button matDialogClose>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center " class="main-content">
    <p class="mat-dialog-font">{{ 'shared.confirm-dialog.sure-cancel' | translate }}</p>
  </div>

  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button matDialogClose cdkFocusInitial>
      {{ ('shared.confirm-dialog.no-button' | translate).toUpperCase() }}
    </button>
    <button mat-button [matDialogClose]="true">
      {{ ('shared.confirm-dialog.yes-button' | translate).toUpperCase() }}
    </button>
  </div>
</div>
