import { CurrencyPipe } from './pipes/ellos-currency.pipe';
import { ShipmentTypeDestination } from './pipes/shipment-type-destination.pipe';
import { ShipmentTypeOrigin } from './pipes/shipment-type-origin.pipe';
import { DashIfNullPipe } from './pipes/dash-if-null.pipe';
import { NavbarComponent } from './../layout/navbar/navbar.component';
// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// Libraries
import { ComponentsLibModule, ResultDialogComponent } from '@ccab/components-lib';
import { TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TruncateModule } from 'ng2-truncate';
import { AvatarModule } from 'ngx-avatars';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { TradeComponent } from '../modules/easy-trade/pages/trade/trade.component';
import { BlockchainAnimationComponent } from './components/blockchain-animation/blockchain-animation.component';
import { ChatComponent } from './components/chat/chat.component';
import { CompaniesImporterAutocompleteComponent } from './components/companies-importer-autocomplete/companies-importer-autocomplete.component';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DotLoaderComponent } from './components/dot-loader/dot-loader.component';
import { EllosOptionToggleComponent } from './components/ellos-option-toggle/ellos-option-toggle.component';
import { EllosSpinnerComponent } from './components/ellos-spinner/ellos-spinner.component';
import { ManualComponent } from './components/manual/manual.component';
import { PdfModalComponent } from './components/pdf-modal/pdf-modal.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { RegisterCompanyComponent } from './components/register-company/register-company.component';
import { CreateAccountRegisterCompany } from './components/create-account-register-company/create-account-register-company.component';
import { ResultNotFoundComponent } from './components/result-not-found/result-not-found.component';
import { CardOptionComponent } from './components/select-card-options/card-option/card-option.component';
import { SelectCardOptionsComponent } from './components/select-card-options/select-card-options.component';
import { ShareBoxComponent } from './components/share-box/share-box.component';
import { ShowroomBreadcrumbComponent } from './components/showroom-breadcrumb/showroom-breadcrumb.component';
import { TimezoneComponent } from './components/timezone/timezone.component';
import { TourComponent } from './components/tour/tour.component';
import { BadgeLimitDirective } from './directive/badge-limit.directive';
import { BlockByCompanyProfileDirective } from './directive/block-by-company-profile.directive';
import { CapsLockOnDirective } from './directive/caps-lock-on.directive';
import { CardBackgroundDirective } from './directive/card-background.directive';
import { ElevateDirective } from './directive/elevate.directive';
import { OnlyNumberDirective } from './directive/only-number.directive';
import { PreventEmojiDirective } from './directive/prevent-emoji.directive';
import { SpinnerButtonDirective } from './directive/spinner-button.directive';
import { TrimInputDirective } from './directive/trim-input.directive';
import { ConfirmRejectDialogComponent } from './components/confirm-reject-dialog/confirm-reject-dialog.component';
// Project
import { MaterialModule } from './material.module';
import { EllosDatePipe } from './pipes/ellos-date.pipe';
import { CheckoutEasytradeComponent } from '../modules/payment/components/checkout-easytrade/checkout-easytrade.component';
import { CheckoutEasytradeResumeComponent } from '../modules/payment/components/checkout-easytrade-resume/checkout-easytrade-resume.component';
import { CountryService } from './services/country.service';
import { CrmService } from './services/crm-service.service';
import { LoadingComponent } from './components/loading/loading.component';
import { NcmService } from './services/ncm.service';
import { NotificationService } from './services/notification.service';
import { PaymentEasytradeTablePaidComponent } from '../modules/payment/components/payment-easytrade-table-paid/payment-easytrade-table-paid.component';
import { PrintService } from './services/print.service';
import { TimezoneService } from './services/timezone.service';
import { EllosCountryPipeCodeAlpcha3 } from './pipes/ellos-country-code-alpha3.pipe';
import { CertificatesDialogComponent } from './components/certificates-dialog/certificates-dialog.component';
import { ProductsToRemoveDialogComponent } from './components/products-to-remove-dialog/products-to-remove-dialog.component';
import { StatusLabelComponent } from '../modules/easy-trade/components/status-label/status-label.component';
import { TransshipmentFlowComponent } from '../modules/certificates/components/transshipment-flow/transshipment-flow.component';
import { CertifiersNameByStringPipe } from './pipes/certifiers-name-by-string.pipe';
import { RemoveSpecialCharactersDirective } from './directive/remove-special-characters.directive';
import { PortAirportAutocompleteNativeComponent } from './components/port-airport-autocomplete-native/port-airport-autocomplete-native.component';
import { ResetExportationDialogComponent } from './components/reset-exportation-dialog/reset-exportation-dialog.component';
import { DeactivateDialogComponent } from './components/deactivate-dialog/deactivate-dialog.component';
import { CertificateNcmAutocompleteComponent } from './components/certificate-ncm-autocomplete/certificate-ncm-autocomplete.component';
import { NcmAutocompleteLocalComponent } from './components/ncm-autocomplete/ncm-autocomplete.component';
import { EllosDatePipeIso } from './pipes/ellos-date-iso.pipe';
import { ApproveRequestDialogComponent } from './components/approve-request-dialog/approve-request-dialog.component';
import { RequestSendDialogComponent } from './components/request-send-dialog/request-send-dialog.component';
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    MaterialModule,
    ComponentsLibModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    CurrencyMaskModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TruncateModule,
    AvatarModule,
    NgxMaterialTimepickerModule,
    NgxMaskModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    NgxMatIntlTelInputComponent,
    NgxMatSelectSearchModule,
    MaterialModule,
    TourMatMenuModule,
    ImageCropperModule,
    FontAwesomeModule
  ],
  exports: [
    NcmAutocompleteLocalComponent,
    CertificateNcmAutocompleteComponent,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    OnlyNumberDirective,
    RemoveSpecialCharactersDirective,
    SpinnerButtonDirective,
    EllosSpinnerComponent,
    CurrencyMaskModule,
    ChatComponent,
    NgxMaskModule,
    TruncateModule,
    NgxMaterialTimepickerModule,
    ElevateDirective,
    ResultDialogComponent,
    InfiniteScrollModule,
    EllosOptionToggleComponent,
    ShareBoxComponent,
    ResultNotFoundComponent,
    AvatarModule,
    ProfileImageComponent,
    NgxMatIntlTelInputComponent,
    NgxMatSelectSearchModule,
    TimezoneComponent,
    CreateAccountRegisterCompany,
    CompanySelectorComponent,
    DotLoaderComponent,
    BadgeLimitDirective,
    ShowroomBreadcrumbComponent,
    TourComponent,
    BlockchainAnimationComponent,
    CompaniesImporterAutocompleteComponent,
    EllosDatePipe,
    EllosDatePipeIso,
    EllosCountryPipeCodeAlpcha3,
    ShipmentTypeOrigin,
    DashIfNullPipe,
    CertifiersNameByStringPipe,
    ShipmentTypeDestination,
    PreventEmojiDirective,
    ImageCropperModule,
    FontAwesomeModule,
    ConfirmDialogComponent,
    ApproveRequestDialogComponent,
    RequestSendDialogComponent,
    ConfirmRejectDialogComponent,
    SuccessDialogComponent,
    SelectCardOptionsComponent,
    CardOptionComponent,
    TrimInputDirective,
    BlockByCompanyProfileDirective,
    CapsLockOnDirective,
    CardBackgroundDirective,
    NavbarComponent,
    CheckoutEasytradeComponent,
    CheckoutEasytradeResumeComponent,
    PaymentEasytradeTablePaidComponent,
    LoadingComponent,
    TransshipmentFlowComponent,
    CertificatesDialogComponent,
    ProductsToRemoveDialogComponent,
    StatusLabelComponent,
    PortAirportAutocompleteNativeComponent,
    RegisterCompanyComponent,
    ResetExportationDialogComponent,
    DeactivateDialogComponent
  ],
  declarations: [
    NcmAutocompleteLocalComponent,
    CertificateNcmAutocompleteComponent,
    NavbarComponent,
    OnlyNumberDirective,
    RemoveSpecialCharactersDirective,
    StatusLabelComponent,
    SpinnerButtonDirective,
    EllosSpinnerComponent,
    ChatComponent,
    PdfModalComponent,
    EllosOptionToggleComponent,
    ShareBoxComponent,
    ResultNotFoundComponent,
    ElevateDirective,
    ProfileImageComponent,
    TimezoneComponent,
    CreateAccountRegisterCompany,
    CompanySelectorComponent,
    DotLoaderComponent,
    ShowroomBreadcrumbComponent,
    ManualComponent,
    BadgeLimitDirective,
    TourComponent,
    BlockchainAnimationComponent,
    TradeComponent,
    CompaniesImporterAutocompleteComponent,
    EllosDatePipe,
    EllosDatePipeIso,
    EllosCountryPipeCodeAlpcha3,
    ShipmentTypeOrigin,
    ShipmentTypeDestination,
    DashIfNullPipe,
    CertifiersNameByStringPipe,
    PreventEmojiDirective,
    ConfirmDialogComponent,
    ApproveRequestDialogComponent,
    RequestSendDialogComponent,
    ConfirmRejectDialogComponent,
    SuccessDialogComponent,
    SelectCardOptionsComponent,
    CardOptionComponent,
    TrimInputDirective,
    BlockByCompanyProfileDirective,
    CapsLockOnDirective,
    CardBackgroundDirective,
    CheckoutEasytradeComponent,
    CheckoutEasytradeResumeComponent,
    PaymentEasytradeTablePaidComponent,
    LoadingComponent,
    TransshipmentFlowComponent,
    CertificatesDialogComponent,
    ProductsToRemoveDialogComponent,
    PortAirportAutocompleteNativeComponent,
    RegisterCompanyComponent,
    ResetExportationDialogComponent,
    DeactivateDialogComponent
  ],
  providers: [CountryService, TimezoneService, CrmService, PrintService, NcmService, NotificationService, CurrencyPipe]
})
export class SharedModule {}
