<div class="container-all">
  <div id="modal-template-name" class="sub-container">
    <header id="modal-header">
      <div mat-dialog-title>
        <div fxLayout="row" fxLayoutAlign="space-between center ">
          <h1 id="modal-title">{{ 'easy-trade.origin.modal.title' | translate }}</h1>
          <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
    </header>

    <div mat-dialog-content class="body">
      <span class="spanUploadFile" *ngIf="UploadFilesSpan">{{ 'easy-trade.origin.modal.span-uploadfile' | translate }}</span>
      <span class="spanUploadFile" *ngIf="amountOfCertificates">{{ 'easy-trade.origin.modal.amount-certificates' | translate }} </span>

      <mat-card
        class="mat-elevation-z8 card"
        (dragover)="onDragOverEvent($event)"
        (dragleave)="isDragOver = false"
        (mouseleave)="isDragOver = false"
        (drop)="onFileDrop($event)"
        cardBackground
        lightBg="#fff"
        [lightColor]="'#051D38'"
        [darkBg]="'#205679'"
        darkColor="#fff"
      >
        <mat-card-content class="card__content" fxLayout="column" fxLayoutAlign="space-between space-between">
          <div class="card__content--upload upload" fxLayout="column" fxLayoutAlign="center center" (click)="fileUpload.click()">
            <mat-icon [class]="isDragOver || _change?.file ? 'upload__icon-display' : 'upload__icon-display upload__icon-display material-icons-outlined'"> cloud_upload</mat-icon>
            <div>
              <p class="upload__message">{{ 'easy-trade.new-trade.stepper.details-requirements.upload-document' | translate }}</p>
              <span class="upload__file-info file-info" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let file of UploadFiles">
                <p class="file-info__file-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon class="material-icons-outlined file-name--file-icon">text_snippet</mat-icon>
                  <span>{{ file.certificate.name }}</span>
                </p>
                <button type="file-info__button" mat-icon-button (click)="removeFile($event, file)">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <span class="error-message" *ngIf="fileErrorMsg">{{ fileErrorMsg }}</span>
          <input style="display: none" (click)="$event.target.value = null" type="file" #fileUpload (change)="fileLoaded($event.target.files)" />
        </mat-card-content>
      </mat-card>
    </div>

    <div class="container-co">
      <span class="title_row">Total: {{ certificados.length }}</span>
      <span class="row cert" *ngFor="let cert of certificados; let i = index">
        {{ 'easy-trade.origin-certificate.table.certificate' | translate }}: {{ i + 1 }} - {{ cert[6] | slice : 10 : 61 }} -
        {{ 'easy-trade.new-trade.stepper.finish.tab.products' | translate }}: {{ countProducts(cert) }}
        <span *ngIf="loading">
          <mat-spinner [diameter]="20" color="accent"></mat-spinner>
        </span>
        <span *ngIf="!loading && isFinished">
          <div *ngIf="checkRequestByIndex(i); then content; else other_content"></div>
          <ng-template #content><mat-icon class="error">close</mat-icon></ng-template>
          <ng-template #other_content><mat-icon class="sucess">done</mat-icon></ng-template>
          <span class="row cert" *ngFor="let error of getErrorMessage(i)">
            <p class="error">{{ error }}</p>
          </span>
        </span>
      </span>
    </div>
  </div>
  <footer id="modal-footer">
    <div mat-dialog-actions [class.pt_Footer]="code == 'pt'" [class.en_Footer]="code == 'en'">
      <button mat-stroked-button class="button" id="modal-cancel-button" (click)="dialogRef.close(false)">
        {{ 'easy-trade.origin.modal.button.cancel' | translate }}
      </button>
      <button mat-stroked-button class="button" (click)="save()" [disabled]="!UploadFiles.length || loading || isFinished" [class.spinner]="loading">
        {{ 'easy-trade.origin.modal.button.save' | translate }}
      </button>
    </div>
  </footer>
</div>
