<app-exportation-product-form
  [useProductValue]="useProductValue"
  [productsRequest]="productsGrid"
  [editProduct]="productToEdit"
  [isDueFormReadOnly]="isDue"
  [isEditProduct]="productEdition"
  [isOriginCertificate]="isOriginCertificate"
  [defaultNcm]="defaultNcm"
  [defaultquantity]="defaultquantity"
  [defaultUnitMeasure]="defaultUnitMeasure"
  [defaultUnitMeasureId]="defaultUnitMeasureId"
  [defaultDescriptionProduct]="defaultDescriptionProduct"
  [defaultNetWeight]="defaultNetWeight"
  [defaultGrossWeight]="defaultGrossWeight"
  (productAdded)="rollbackProduct($event)"
>
</app-exportation-product-form>

<app-product-exportation-grid
  [useProductValue]="useProductValue"
  [canEdit]="canEdit"
  [isEditProduct]="productEdition"
  [isOriginCertificate]="isOriginCertificate"
  [dataSource]="productsGrid"
  (deletedProduct)="deleteProduct($event)"
  (editProductEvent)="editProduct($event)"
>
</app-product-exportation-grid>
