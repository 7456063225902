<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="center center ">
      <span></span>
      <p>{{ 'shared.request-aproval-dialog.title' | translate }}</p>
      <button mat-icon-button matDialogClose style="position: absolute; top: 0; right: 0">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center" class="main-content">
    <p class="mat-dialog-font" style="font-size: 18px !important" color="primary" [innerHTML]="'shared.request-aproval-dialog.label' | translate"></p>
  </div>

  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button mat-button [matDialogClose]="true" class="btn-color" styel="text-aling: center;" (click)="RedirectToMonitoring()">
      {{ ('shared.request-aproval-dialog.yes-button' | translate).toUpperCase() }}
    </button>
  </div>
</div>
