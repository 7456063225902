<div class="group-area" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="180px">
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.name-company' | translate" [label]="company?.name"> </app-title-and-label>
  </div>
</div>

<div class="group-area" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="180px">
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.state' | translate" [label]="company?.state"> </app-title-and-label>
  </div>

  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.city' | translate" [label]="company?.city"> </app-title-and-label>
  </div>

  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.po-box' | translate" [label]="company?.poBox"> </app-title-and-label>
  </div>
</div>

<div class="group-area" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="180px">
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.address' | translate" [label]="company?.address"> </app-title-and-label>
  </div>

  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.district' | translate" [label]="company?.district"> </app-title-and-label>
  </div>

  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
    <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.address-complement' | translate" [label]="company?.complement"> </app-title-and-label>
  </div>
</div>
