import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { OriginCertificateStatusEnum } from 'src/app/modules/easy-trade/enums/origin.enum';
import { pageConfig } from 'src/app/modules/easy-trade/actions/origin-certificate-query.action';
import { OriginCertificateService } from 'src/app/modules/easy-trade/services/origin-certificate.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ModalCertificateEdit } from '../../modals/modal-certificate-edit/modal-certificate-edit.component';
import { OriginCertificateQuery } from 'src/app/modules/easy-trade/interfaces/OriginCertificateQuery.interface';
import { OriginCertificatePreviewResponse } from 'src/app/modules/easy-trade/Models/origin-certificate-preview-response.interface';
import { RequestPaymentComponent } from 'src/app/shared/components/request-payment/request-payment.component';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { shareReplay } from 'rxjs/operators';
import { EasyTradeService } from 'src/app/modules/easy-trade/services/easy-trade.service';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-origin-certificate-table [dataSource]',
  templateUrl: './origin-certificate-table.component.html',
  styleUrls: ['./origin-certificate-table.component.scss']
})
export class TableOriginCertificateComponent implements OnInit, OnChanges {
  @Input() dataSource: OriginCertificatePreviewResponse;
  @Output() selectedDownload = new EventEmitter<OriginCertificatePreviewResponse>();
  originCertificateQuery$: Observable<OriginCertificateQuery>;
  public query: OriginCertificateQuery;
  pageIndex: number;
  count: number;
  checked: boolean = false;
  ocStatus = OriginCertificateStatusEnum;
  displayedColumns = ['checkbox', 'certificate', 'date', 'protocol', 'process', 'invoice', 'invoiceDate', 'destinationCountry', 'importer', 'status', 'actions'];

  @Output() reloadTable: EventEmitter<boolean> = new EventEmitter<boolean>();
  destroy$ = new Subject<any>();
  constructor(
    private readonly originCertificateQueryStore: Store<{
      originCertificateQuery: OriginCertificateQuery;
    }>,
    private readonly originCertificateService: OriginCertificateService,
    private readonly easytradeService: EasyTradeService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private router: Router
  ) {
    this.originCertificateQuery$ = originCertificateQueryStore.pipe(select('originCertificateQuery'));
  }
  ngOnInit(): void {
    this.originCertificateQuery$.pipe(takeUntil(this.destroy$), shareReplay(1)).subscribe((query) => {
      if (query) {
        this.query = query;
        this.pageIndex = query.pageIndex;
        this.count = this.dataSource?.total || 0;
      }
    });
  }

  ngOnChanges(): void {
    console.log(this.dataSource);
  }

  filterPage(index: any) {
    this.originCertificateQueryStore.dispatch(pageConfig({ pageIndex: index.pageIndex, pageSize: index.pageSize }));
  }

  hideImage(event: Event) {
    const element = event.target as HTMLImageElement;
    element.style.display = 'none';
  }

  async downloadOC(id: number, status: OriginCertificateStatusEnum, certificate: number, invoice: string) {
    try {
      if (status == 0 || status == 1 || status == 2) {
        let file = await this.originCertificateService.downloadPDF(id, status < OriginCertificateStatusEnum.Registered);
        const source = `data:application/pdf;base64,${file.fileContents}`;
        const link = document.createElement('a');
        link.href = source;
        link.download =
          status < OriginCertificateStatusEnum.Registered
            ? `${invoice.split(' ').join('_').split('/').join('_').split('\\').join('_')}_${certificate}_draft.pdf`
            : `${invoice.split(' ').join('_').split('/').join('_').split('\\').join('_')}_${certificate}.pdf`;
        link.click();
      } else {
        let file = await this.originCertificateService.downloadPDF(id, status < OriginCertificateStatusEnum.Registered);
        const response = await fetch(file.fileContents);
        const data = await response.blob();
        const blob = new Blob([data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `origin_certificate_${id}.pdf`);
        link.click();
        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.log('Error on download OC', error);
    }
  }

  async deleteOC(id: number) {
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent);
      const rest = await dialogRef.afterClosed().toPromise();

      if (rest) {
        let resp = await this.originCertificateService.deleteCertificate(id);
        if (resp) {
          let msg: string = this.translateService.instant('easy-trade.origin-certificate.delete-success');
          this.notifySuccess(msg);
          this.reloadTable.emit(true);
        } else {
          let msg: string = this.translateService.instant('easy-trade.origin-certificate.delete-error');
          this.notifyError(msg);
        }
      }
    } catch (error) {
      console.log('Error on delete OC');
    } finally {
    }
  }

  async duplicateOC(id: number) {
    try {
      let resp = await this.originCertificateService.duplicateCertificate(id);
      if (resp) {
        let msg: string = this.translateService.instant('easy-trade.origin-certificate.duplicate-success');
        this.notifySuccess(msg);
        this.reloadTable.emit(true);
      } else {
        let msg: string = this.translateService.instant('easy-trade.origin-certificate.duplicate-error');
        this.notifyError(msg);
      }
    } catch (error) {
      console.log('Error on delete OC');
    } finally {
    }
  }

  private notifySuccess(msg: string) {
    this.notificationService.notifyDialog({
      success: true,
      timeout: 3000,
      text: msg
    });
  }

  private notifyError(msg: string) {
    this.notificationService.notifyDialog({
      success: false,
      timeout: 3000,
      text: msg
    });
  }

  selectUnselectAll() {
    this.dataSource.results.forEach((item) => {
      if (item.status === 3) {
        item.checkbox = this.checked;
      }
    });
  }

  selectCertificate(event, certificate) {
    this.dataSource.results.forEach((item) => {
      if (item.id == certificate.id) {
        item.checkbox = event.checked;
      }
    });
  }
  async validateCObyBl(idCO: number, requestApproval: boolean) {
    let data = await this.originCertificateService.getOriginCertificate(idCO);
    if (data?.shipmentInformation?.blawbNumber || requestApproval) {
      this.router.navigate(['/EasyTrade/Trade/NewTradeByCo', data.id], {
        queryParams: { requestApproval: requestApproval }
      });
    } else {
      let dialogRef = this.dialog.open(ModalCertificateEdit, {
        data: data,
        width: '60%'
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (response != null) {
            console.log('resposta modal', response);
            if (response) {
              this.router.navigate(['/EasyTrade/Trade/NewTradeByCo', data.id], {
                queryParams: { requestApproval: requestApproval }
              });
            } else {
              this.notifyerror();
            }
          }
        });
    }
  }
  public async openModalPaymet(CertificateID: number) {
    const response = await this.easytradeService.getCertificatePayment(CertificateID);
    this.dialog.open(RequestPaymentComponent, {
      data: {
        amount: response.data.amount,
        qrCodeBase64: response.data.qrCodeBase64,
        pixCopyPaste: response.data.pixCopyPaste
      }
    });
  }
  private notifyerror() {
    let msg: string = this.translateService.instant('easy-trade.origin-certificate.error');
    this.notificationService.notifyDialog({
      success: false,
      timeout: 3000,
      text: msg
    });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
