<section fxLayout="column" class="search-area">
  <div class="title-area" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start" fxLayoutAlign="start start">
    <div class="title">
      <h2 class="subtitle">
        {{ 'certificates.certificates' | translate }}
      </h2>
      <h2 color="Primary">Easy Trade</h2>
    </div>
  </div>
  <div fxLayoutGap="20px" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
    <search-origin-certificate
      fxFlex="auto"
      fxFlex.lt-md="100"
      *ngIf="modules.includes(modulePermission.PesquisarCertificadosEasytrade)"
      (certificateNum)="filtercertificateNum($event)"
      (invoiceNumber)="filterInvoiceNumber($event)"
      (paste)="handlePaste($event)"
    >
    </search-origin-certificate>

    <ng-container *ngIf="modules.includes(modulePermission.NovaExportacaoEasytrade)">
      <a *blockCompanyProfile="['EMB']" mat-flat-button class="new-certificate-button" color="accent" routerLink="/Certificates/Certificateorigin/NewCertificateorigin">
        {{ 'easy-trade.origin.button.new-certificate' | translate }}
      </a>
      <a mat-flat-button class="import-certificate-button" color="accent" (click)="openModal()" *ngIf="!showAllDownloadButton() && !showSelectedDownloadButton()">
        <mat-icon class="upload__icon-display upload__icon-display material-icons-outlined"> cloud_upload</mat-icon>
        {{ 'easy-trade.origin.button.import-file' | translate }}
      </a>
    </ng-container>

    <a mat-flat-button class="download-all-button" color="accent" *ngIf="showAllDownloadButton()" (click)="downloadCertificates()">
      <mat-icon class="download-display material-icons-outlined"> download</mat-icon>
      {{ 'easy-trade.origin.button.download-all' | translate }}
    </a>
    <a mat-flat-button class="download-selected-button" color="accent" *ngIf="showSelectedDownloadButton()" (click)="downloadCertificates()">
      <mat-icon class="download-display material-icons-outlined"> download</mat-icon>
      {{ 'easy-trade.origin.button.download-selected' | translate }}
    </a>
  </div>
</section>

<app-origin-certificate-table (reloadTable)="reloadTable($event)" [dataSource]="dataSource"></app-origin-certificate-table>
