import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateValidator } from './../../../../../shared/validators/date.validator';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { DateInputComponent } from '@ccab/components-lib';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  QueryList,
  ViewChildren,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Language from 'src/app/core/interfaces/language.interface';
import { EnumMandatoryNcm, Ncm } from 'src/app/shared/models/ncm.interface';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProductExportation } from '../../../Models/product-exportation.interface';
import { TranslateService } from '@ngx-translate/core';
import { ProductCurrencyEnum } from '../../../enums/product-currency.enum';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { NcmService } from 'src/app/shared/services/ncm.service';

interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}
import { checkGrossValidator } from './check-gross-weight-and-net-weight.validator';
import { checkExpirationDate } from './check-expiration-date.validator';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { CertificateNcmAutocompleteComponent } from 'src/app/shared/components/certificate-ncm-autocomplete/certificate-ncm-autocomplete.component';

interface FormGroupControls {
  [key: string]: AbstractControl;
}

@Component({
  selector: 'app-exportation-product-form',
  templateUrl: './exportation-product-form.component.html',
  styleUrls: ['./exportation-product-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class ExportationProductFormComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInputQuantity') searchInputQuantity: ElementRef;
  @ViewChild('inputGrossWeight') inputGrossWeight: ElementRef;
  @ViewChild('inputNetWeight') inputNetWeight: ElementRef;
  productFormGroup: FormGroup = this.newProductForm();
  cleanNcm: boolean;
  isUpdateNameNcm: boolean = false;
  @ViewChild('ncmProduct') private ncmProduct: CertificateNcmAutocompleteComponent;

  @ViewChildren(DateInputComponent)
  dateInputComponent: QueryList<DateInputComponent>;

  @ViewChild('expirationDate') pickerExpirationDate: MatDatepicker<Moment>;
  ErrorTypeExpirationDate: string[] = [];
  ErrorTypeDateProduction: string[] = [];

  @Input() productsRequest: ProductExportation[] = [];
  @Input() useProductValue: boolean = false;
  dataSource = new MatTableDataSource<ProductExportation>([]);
  language$: Observable<Language>;
  languageCode: string;
  destroy$ = new Subject();
  _isOriginCertificate: boolean = false;
  isErrorExpirationDate: boolean;
  isErrorDateProduction: boolean;

  urlUnitMeasureService: string = environment.showcaseApi;
  urlNcmService: string = environment.ncmApi;

  @Output() productAdded: EventEmitter<ProductExportation> = new EventEmitter<ProductExportation>();
  @Input() editProduct: ProductExportation;
  @Input() defaultNcm: Ncm;
  @Input() defaultquantity: any;
  @Input() defaultUnitMeasure: any;
  @Input() defaultUnitMeasureId: any;
  @Input() defaultDescriptionProduct: any;
  @Input() defaultNetWeight: any;
  @Input() defaultGrossWeight: any;
  editProductBakcup: ProductExportation;
  nameNcmDefault: string = '';
  @Input() isEditProduct: boolean = false;
  @Input() isDueFormReadOnly: boolean = false;

  // DATA
  mask: string;
  dateFormatStringProduction: string;
  dateFormatStringExpirationDate: string;
  inputStringDateExpirationDate = new FormControl(null);
  inputStringDateProduction = new FormControl(null);
  minDateProduction: string;
  minDateExpirationDate: string;
  maxDateProduction: string;
  maxDateExpirationDate: string;

  @Input() set isOriginCertificate(value: boolean) {
    this._isOriginCertificate = value;
  }

  public currencyEnum = ProductCurrencyEnum;
  public currencyEnumKeys = Object.keys(this.currencyEnum)
    .filter((f) => !isNaN(Number(f)))
    .map((k) => Number(k));
  public currencyMapConfig: { [key: string]: CurrencyMaskConfig } = {};
  public currentCurrencyConfig: CurrencyMaskConfig = {
    prefix: 'USD ',
    thousands: ',',
    decimal: '.',
    precision: 2,
    allowNegative: true,
    align: 'right',
    suffix: ''
  };

  alreadyInitUpgradeForm: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly langStore: Store<{ language: Language }>,
    private readonly ncmService: NcmService,
    private readonly translateService: TranslateService,
    public datepipe: DatePipe,
    private _adapter: DateAdapter<any>
  ) {
    this.language$ = langStore.pipe(select('language'));
  }

  ngOnInit(): void {
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      if (lang) {
        this.languageCode = lang.code;
        this.dateFormatStringProduction = this.convertDateToString(this.productFormGroup.controls['manufactoringDate'].value);
        this.dateFormatStringExpirationDate = this.convertDateToString(this.productFormGroup.controls['expirationDate'].value);
        if (this.languageCode == 'en') {
          this._adapter.setLocale('en');
          this.mask = 'MM/DD/YYYY';
        } else {
          this.mask = 'DD/MM/AAAA';
          this._adapter.setLocale('pt-BR');
        }
        this.productFormGroup.controls['manufactoringDate'].setValidators([
          DateValidator.minDate(this.minDateProduction, this.languageCode),
          DateValidator.maxDate(this.maxDateProduction, this.languageCode)
        ]);
      }
    });

    this.InitCurrencyConfigs();

    this.productFormGroup = this.newProductForm();
  }

  ngAfterViewInit(): void {
    if (this.defaultquantity) {
      this.productFormGroup.controls.quantity.setValue(this.defaultquantity);
      this.searchInputQuantity.nativeElement.value = this.defaultquantity;
    }

    if (this.defaultGrossWeight !== null && this.defaultGrossWeight !== undefined) {
      this.productFormGroup.controls.grossWeight.setValue(this.defaultGrossWeight);
      this.inputGrossWeight.nativeElement.value = this.defaultGrossWeight;
    }

    if (this.defaultNetWeight !== null && this.defaultNetWeight !== undefined) {
      this.productFormGroup.controls.netWeight.setValue(this.defaultNetWeight);
      this.inputNetWeight.nativeElement.value = this.defaultGrossWeight;
    }
  }

  setDateProduction(value) {
    this.dateFormatStringProduction = this.convertDateToString(value.value);
    this.resetStatusOfDates();
  }

  setDateExpirationDate(value) {
    this.dateFormatStringExpirationDate = this.convertDateToString(value.value);
    this.resetStatusOfDates();
  }

  convertDateToString(value) {
    const typeOfMask = this.languageCode == 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    return this.datepipe.transform(value, typeOfMask);
  }

  validateDateProduction() {
    if (this.dateFormatStringProduction?.length === 0) {
      this.isErrorDateProduction = false;
      return;
    }
    if (this.dateFormatStringProduction?.length === 10) {
      const [month, day, year] = this.dateFormatStringProduction.split('/');
      const date = this.languageCode == 'en' ? new Date(+year, +month - 1, +day) : new Date(+year, +day - 1, +month);

      if (!this.validateDayMonthAndYear(month, day, year, this.languageCode)) {
        this.productFormGroup.controls['manufactoringDate'].setValue(date);
        this.isErrorDateProduction = false;
      } else {
        this.isErrorDateProduction = true;
      }
    } else {
      this.productFormGroup.controls['manufactoringDate'].setValue(null);
      this.isErrorDateProduction = true;
      const [month, day, year] = this.dateFormatStringProduction.split('/');
      this.ErrorTypeDateProduction = [];
      if (year?.length !== 4 && this.dateFormatStringProduction.length > 6) {
        this.ErrorTypeDateProduction.push(this.languageCode == 'en' ? 'Invalid Year' : 'Ano Invalido');
      }
    }
  }

  invalidExpirationDate() {
    let FORMATO_DATA_INVALIDA = false;
    FORMATO_DATA_INVALIDA = this.productFormGroup.errors?.checkExpirationDate === true || this.isErrorExpirationDate;
    if (FORMATO_DATA_INVALIDA === undefined) {
      FORMATO_DATA_INVALIDA = false;
    }
    return FORMATO_DATA_INVALIDA;
  }

  validateDateExpirationDate() {
    if (this.dateFormatStringExpirationDate?.length === 10) {
      const [month, day, year] = this.dateFormatStringExpirationDate?.split('/');
      const date = this.languageCode == 'en' ? new Date(+year, +month - 1, +day) : new Date(+year, +day - 1, +month);

      if (!this.validateDayMonthAndYear(month, day, year, this.languageCode)) {
        this.productFormGroup.controls['expirationDate'].setValue(date);
        this.isErrorExpirationDate = false;
      } else {
        this.isErrorExpirationDate = true;
      }
    } else {
      this.productFormGroup.controls['expirationDate'].setValue(null);
      this.isErrorExpirationDate = true;
      const [month, day, year] = this.dateFormatStringExpirationDate.split('/');
      this.ErrorTypeExpirationDate = [];
      if (year?.length !== 4 && this.dateFormatStringExpirationDate.length > 6) {
        this.ErrorTypeExpirationDate.push(this.languageCode == 'en' ? 'Invalid Year' : 'Ano Invalido');
      }
    }
  }

  validateDayMonthAndYear(month, day, year, languageCode): boolean {
    this.ErrorTypeExpirationDate = [];
    if (languageCode === 'en') {
      if (month > 12 || day > 31) {
        if (month > 12) {
          this.ErrorTypeExpirationDate.push('Invalid Month');
        }
        if (day > 31) {
          this.ErrorTypeExpirationDate.push('Invalid Day');
        }
        return true;
      } else {
        this.ErrorTypeExpirationDate = [];
        return false;
      }
    } else {
      if (day > 12 || month > 31) {
        if (day > 12) {
          this.ErrorTypeExpirationDate.push('Mês Inválido');
        }
        if (month > 31) {
          this.ErrorTypeExpirationDate.push('Dia Inválido');
        }
        return true;
      } else {
        this.ErrorTypeExpirationDate = [];
        return false;
      }
    }
  }

  checkTypeExpirationDate(): boolean {
    if (this?.dateFormatStringExpirationDate?.length) {
      if (this.dateFormatStringExpirationDate.length === 10) {
        if (this.invalidExpirationDate()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private InitCurrencyConfigs() {
    this.currencyMapConfig[this.currencyEnum.DOLLAR] = {
      prefix: 'USD ',
      thousands: ',',
      decimal: '.',
      precision: 2,
      allowNegative: true,
      align: 'right',
      suffix: ''
    };

    this.currencyMapConfig[this.currencyEnum.REAL] = {
      prefix: 'BRL ',
      thousands: '.',
      decimal: ',',
      precision: 2,
      allowNegative: true,
      align: 'right',
      suffix: ''
    };

    this.currencyMapConfig[this.currencyEnum.EURO] = {
      prefix: 'EUR ',
      thousands: '.',
      decimal: ',',
      precision: 2,
      allowNegative: true,
      align: 'right',
      suffix: ''
    };
  }

  setCurrencyConfig(currency: ProductCurrencyEnum) {
    this.currentCurrencyConfig = this.currencyMapConfig[currency];
  }

  ngDoCheck() {
    if (!this.alreadyInitUpgradeForm && this.productsRequest.length > 0) {
      this.dataSource = new MatTableDataSource<ProductExportation>(this.productsRequest);
      this.productFormGroup.controls.unitMeasureWeight.setValue(this.productsRequest[0].unitMeasureWeight);
      this.alreadyInitUpgradeForm = true;
    }
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.editProduct) {
      this.resetStatusOfDates();
      this.editProductBakcup = { ...this.editProduct };
      this.nameNcmDefault = '';
      if (this.editProduct?.ncm) {
        setTimeout(() => {
          this.nameNcmDefault = `${this.editProduct?.ncm?.ncmCode} - ${this.editProduct?.ncm?.description}`;
        }, 0);
      }
      this.productFormGroup = this.newProductForm(this.editProduct);
      this.isUpdateNameNcm = true;
    }

    if (e?.defaultNcm?.currentValue) {
      this.productFormGroup.controls.ncm.setValue(e?.defaultNcm?.currentValue);
      this.productFormGroup.controls.description.setValue(e?.defaultNcm?.currentValue?.description);
    }

    if (this.defaultGrossWeight !== null && this.defaultGrossWeight !== undefined) {
      this.productFormGroup.controls.grossWeight.setValue(this.defaultGrossWeight);
    }

    if (this.defaultNetWeight !== null && this.defaultNetWeight !== undefined) {
      this.productFormGroup.controls.netWeight.setValue(this.defaultNetWeight);
    }

    if (e?.defaultNetWeight?.currentValue) {
      this.productFormGroup.controls.netWeight.setValue(e?.defaultNetWeight?.currentValue);
    }
    if (e?.defaultDescriptionProduct?.currentValue) {
      this.productFormGroup.controls.description.setValue(e?.defaultDescriptionProduct?.currentValue);
    }
  }

  newProductForm(defaultValue?: ProductExportation) {
    const formGroupProduct = this.fb.group(
      {
        ncm: [defaultValue?.ncm || undefined],
        unitMeasure: [defaultValue?.unitMeasure || '', [Validators.required]],
        unitMeasureWeight: [defaultValue ? defaultValue?.unitMeasureWeight : this.productFormGroup?.value?.unitMeasureWeight || null, [Validators.required]],
        quantity: [defaultValue?.quantity || null, [Validators.required]],
        grossWeight: [defaultValue?.grossWeight || null, [Validators.required]],
        netWeight: [defaultValue?.netWeight || null, [Validators.required, checkGrossValidator]],
        manufactoringDate: [defaultValue?.manufactoringDate || null, [Validators.required]],
        expirationDate: [defaultValue?.expirationDate || null, [Validators.required, checkExpirationDate]],
        description: [defaultValue?.description || null, [Validators.required]],
        marksAndNumbers: [defaultValue?.marksAndNumbers || null],
        sif: [defaultValue?.sif || null],
        productValue: [defaultValue?.productValue || null],
        currency: [defaultValue?.currency || ProductCurrencyEnum.DOLLAR]
      },
      {
        validators: [Validators.required, checkGrossValidator, checkExpirationDate]
      }
    );

    const EDICAO_PRODUTO_COM_DATA_DEFAULT: Boolean = defaultValue?.manufactoringDate && defaultValue?.expirationDate ? true : false;
    if (EDICAO_PRODUTO_COM_DATA_DEFAULT) {
      this.dateFormatStringProduction = this.convertDateToString(defaultValue?.manufactoringDate);
      this.dateFormatStringExpirationDate = this.convertDateToString(defaultValue?.expirationDate);
    }

    if (this._isOriginCertificate) {
      formGroupProduct.controls.description.setValidators([Validators.required, Validators.maxLength(500)]);
    }

    if (this.useProductValue) {
      formGroupProduct.controls.productValue.setValidators([Validators.required]);
      formGroupProduct.controls.currency.setValidators([Validators.required]);
    }
    if (!EDICAO_PRODUTO_COM_DATA_DEFAULT) {
      this.resetDateProductForm();
    }
    // Make sure additional validators are up to date on the fields
    formGroupProduct.controls.description.updateValueAndValidity();
    formGroupProduct.controls.manufactoringDate.updateValueAndValidity();
    formGroupProduct.controls.expirationDate.updateValueAndValidity();
    formGroupProduct.controls.productValue.updateValueAndValidity();

    return formGroupProduct;
  }

  resetDateProductForm() {
    this.dateFormatStringProduction = null;
    this.inputStringDateProduction = new FormControl(null);
    this.maxDateProduction = null;
    this.minDateProduction = null;

    this.dateFormatStringExpirationDate = null;
    this.inputStringDateExpirationDate = new FormControl(null);
    this.minDateExpirationDate = null;
    this.maxDateExpirationDate = null;
  }

  isProductionAndExpirationDateRequired(): boolean {
    if (!this.productFormGroup?.value?.ncm?.ncmCode) {
      this.productFormGroup.controls.manufactoringDate.setValidators([Validators.required]);
      this.productFormGroup.controls.expirationDate.setValidators([Validators.required, checkExpirationDate]);
      this.productFormGroup.controls.manufactoringDate.updateValueAndValidity();
      this.productFormGroup.controls.expirationDate.updateValueAndValidity();
      return true;
    }

    const ncmCode = this.productFormGroup.value.ncm.ncmCode;
    const firstDigits = ncmCode.substring(0, 2);
    const firstDigitsNumber = parseInt(firstDigits, 10);

    if (firstDigitsNumber <= 23) {
      this.productFormGroup.controls.manufactoringDate.setValidators([Validators.required]);
      this.productFormGroup.controls.expirationDate.setValidators([Validators.required, checkExpirationDate]);
      this.productFormGroup.controls.manufactoringDate.updateValueAndValidity();
      this.productFormGroup.controls.expirationDate.updateValueAndValidity();
      return true;
    } else {
      this.productFormGroup.controls.manufactoringDate.setValidators(null);
      this.productFormGroup.controls.expirationDate.setValidators(null);
      this.productFormGroup.controls.manufactoringDate.updateValueAndValidity();
      this.productFormGroup.controls.expirationDate.updateValueAndValidity();

      return false;
    }
  }

  addProduct(rollbackProct?: boolean) {
    if (this.productFormGroup.valid && this.productFormGroup.controls.ncm.value != null && this.productFormGroup.controls.ncm.value && !this.checkDisabledButtonAddProdutc()) {
      this.cleanNcm = true;
      this.ncmProduct.cleanFields();
      if (rollbackProct) {
        this.productAdded.emit(this.editProductBakcup);
      } else {
        const data = this.productFormGroup.value;
        this.productAdded.emit(data);
      }

      this.productFormGroup = this.newProductForm();

      let config = {
        buttonText: 'X',
        text: 'easy-trade.new-trade.stepper.product-information.form.success-add-product',
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 1200
      };
      this.notificationService.successSnackBar(config);
      this.isEditProduct = false;
    } else if (!this.productFormGroup.valid) {
      let config = {
        buttonText: 'X',
        text: this.getErrorText(),
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 1200
      };
      this.ncmProduct.inputSearch.markAllAsTouched();
      this.productFormGroup.markAllAsTouched();
      this.notificationService.errorSnackBar(config);
    }
  }

  getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      if (control instanceof FormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors: ValidationErrors = controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push({
            control_name: this.getTranslatedControlName(key),
            error_name: keyError,
            error_value: controlErrors[keyError]
          });
        });
      }
    });
    return errors;
  }

  getTranslatedControlName(controlName: string): string {
    const formatControlName = (str) => str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
    return this.translateService.instant('easy-trade.new-trade.stepper.product-information.form.' + formatControlName(controlName));
  }

  getErrorText(): string {
    const error: AllValidationErrors = this.getFormValidationErrors(this.productFormGroup.controls).shift();
    if (error) {
      let text;
      if (error?.error_value) {
        switch (error.error_name) {
          case 'required':
            text = `${error.control_name} ${this.translateService.instant('easy-trade.origin.required')}`;
            break;
          case 'minlength':
            text = `${error.control_name} ${this.translateService.instant('easy-trade.origin.length')} ${error.error_value.requiredLength}`;
            break;
          case 'maxlength':
            text = `${error.control_name} ${this.translateService.instant('easy-trade.origin.length')} ${error.error_value.requiredLength}`;
            break;
          case 'incorrect-netWeight':
            text = `${this.translateService.instant('easy-trade.new-trade.stepper.product-information.form.net-weight-error')}`;
            break;
          default:
            text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
        }
      }
      return text ? text : '';
    }
    if (this.productFormGroup.errors?.checkGrossValidator) {
      return 'easy-trade.new-trade.stepper.product-information.form.net-weight-error';
    } else {
      return 'easy-trade.new-trade.stepper.product-information.form.success-add-product';
    }
  }

  async getNcms(code) {
    const data = await this.ncmService.getNcmPaginated({
      NcmCode: code,
      LanguageCode: 'en',
      PageIndex: 0,
      PageSize: 10
    });
    return data[0];
  }

  async setProductNcm(ncm: Ncm) {
    this.cleanNcm = false;
    this.productFormGroup.controls.ncm.setValue(ncm);
    const ncmTrasnlate = await this.getNcms(ncm.ncmCode);
    this.nameNcmDefault = `${ncm.ncmCode} - ${ncm?.description}`;
    if (!this.isDueFormReadOnly) {
      this.productFormGroup.controls.description.setValue(ncmTrasnlate.description);
    }
    this.setRequiredFieldBasedOnNcm(ncm);
  }

  isUpdateNameNcmToFalse($event) {
    this.isUpdateNameNcm = $event;
  }

  setRequiredFieldBasedOnNcm(ncm: Ncm) {
    const foundMandatoryForDate = ncm.validations?.find((val) => val.mandatoryDate == EnumMandatoryNcm.Mandatory);

    if (foundMandatoryForDate) {
      this.productFormGroup.controls.expirationDate.setValidators([Validators.required, checkExpirationDate]);
      this.productFormGroup.controls.manufactoringDate.setValidators([Validators.required]);
    } else {
      this.productFormGroup.controls.expirationDate.setValidators([checkExpirationDate]);
      this.productFormGroup.controls.manufactoringDate.setValidators([]);
      this.productFormGroup.controls.expirationDate.setErrors(null);
      this.productFormGroup.controls.manufactoringDate.setErrors(null);
    }

    const foundMandatoryForSif = ncm.validations?.find((val) => val.mandatorySif == EnumMandatoryNcm.Mandatory);

    if (foundMandatoryForSif) {
      this.productFormGroup.controls.sif.setValidators([Validators.required]);
    } else {
      this.productFormGroup.controls.sif.setValidators([]);
      this.productFormGroup.controls.sif.setErrors(null);
    }

    // update field validation
    this.productFormGroup.controls.manufactoringDate.updateValueAndValidity();
    this.productFormGroup.controls.expirationDate.updateValueAndValidity();
    this.productFormGroup.controls.sif.updateValueAndValidity();
  }

  getMinDateToShelfDate() {
    if (this.productFormGroup.errors?.checkExpirationDate) {
      {
        this.productFormGroup.controls['expirationDate'].setErrors({
          'incorrect-expirationDate': true,
          checkTypeExpirationDate: this.checkTypeExpirationDate()
        });
      }
    } else {
      this.productFormGroup.controls.expirationDate.setErrors(null);
    }
    return this.productFormGroup?.value.manufactoringDate;
  }

  getProductDateInEdit(value: Date) {
    if (value != null) {
      const dia = value.getDay();
      const mes = value.getMonth();
      const ano = value.getFullYear();
      if (this.languageCode == 'pt') {
        return `${dia}/${mes}/${ano}`;
      } else {
        return `${mes}/${dia}/${ano}`;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onChangeNetWeightEvent(valueOfNetWeight: any) {
    const grossWeightControl = this.productFormGroup.controls['grossWeight'];

    valueOfNetWeight > this.productFormGroup.controls['grossWeight'].value
      ? this.productFormGroup.controls['netWeight'].setErrors({ 'incorrect-netWeight': true })
      : this.productFormGroup.controls['netWeight'].setErrors(null);

    if (valueOfNetWeight === 0 || valueOfNetWeight == null) {
      this.productFormGroup.controls['netWeight'].setErrors({
        'incorrect-netWeight': true
      });
    }

    grossWeightControl.updateValueAndValidity();
  }

  setValidationEvent(event) {
    if (event) {
      this.productFormGroup.controls['expirationDate'].setErrors({
        'minDate-incorrect': true
      });
    } else {
      this.productFormGroup.controls['expirationDate'].setErrors({
        'minDate-incorrect': false
      });
    }
    this.productFormGroup.controls.expirationDate.updateValueAndValidity();
  }

  onChangeGrossWeighttEvent(valueOfGrossWeight: any) {
    const netWeightControl = this.productFormGroup.controls['netWeight'];

    valueOfGrossWeight >= this.productFormGroup.controls['netWeight'].value
      ? this.productFormGroup.controls['grossWeight'].setErrors(null)
      : this.productFormGroup.controls['grossWeight'].setErrors({ 'incorrect-netWeight': true });

    if (valueOfGrossWeight === 0 || valueOfGrossWeight == null) {
      this.productFormGroup.controls['grossWeight'].setErrors({
        'incorrect-grossWeight': true
      });
    }

    netWeightControl.updateValueAndValidity();
  }

  checkDisabledButtonAddProdutc(): boolean {
    if (this.isProductionAndExpirationDateRequired()) {
      return !(this.dateFormatStringExpirationDate?.length === 10 && this.dateFormatStringProduction?.length === 10 && !this.productFormGroup.invalid);
    } else {
      return this.productFormGroup.invalid;
    }
  }

  resetStatusOfDates() {
    this.isErrorExpirationDate = false;
    this.isErrorDateProduction = false;
    this.ErrorTypeExpirationDate = [];
    this.ErrorTypeDateProduction = [];
  }
}
