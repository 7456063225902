<div class="timeline">
  <div *ngFor="let transship of transshipments; let i = index" class="content" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon class="icon" *ngIf="i == 0; else checkpoint">
      <fa-icon [icon]="shipmentMethod == transhipmentType.plane ? faPlaneDeparture : faShip"></fa-icon>
    </mat-icon>
    <ng-template #checkpoint>
      <mat-icon class="icon">
        <fa-icon
          [icon]="
            transshipments.length == i + 1 && shipmentMethod == transhipmentType.plane
              ? faPlaneArrival
              : transshipments.length == i + 1 && shipmentMethod == transhipmentType.ship
              ? faAnchor
              : faMapMarkerAlt
          "
        ></fa-icon>
      </mat-icon>
    </ng-template>

    <app-port-airport-autocomplete-native
      fxFlex="70"
      [label]="getLabel(i)"
      [readonlyField]="readonlyField"
      [hiddenFields]="getIds()"
      [reset]="reset"
      [isSummary]="isSummary"
      [isNewTrade]="isNewTrade"
      [transshipmentMethod]="shipmentMethod"
      [CertificateProcessing]="CertificateProcessing"
      [defaultTransshipment]="transship"
      (selectedTransshipment$)="setTransshipment($event, i)"
      (resetEvent)="emitReset()"
      (validateTransshipment$)="validateTransshipment($event)"
    ></app-port-airport-autocomplete-native>

    <button mat-icon-button (click)="removeTranshipment(i)" *ngIf="transshipments.length > 2 && !readonlyField">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center start" *ngIf="!readonlyField && isNewTrade">
  <button class="add-button" mat-stroked-button type="button" (click)="addShipment()">
    {{ 'easy-trade.new-trade.stepper.shipment-information.add_transshipment' | translate }}
  </button>
</div>
