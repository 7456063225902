<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="center center ">
      <span></span>
      <p>{{ 'shared.deactivate-dialog.data-lost' | translate }}</p>
      <button mat-icon-button matDialogClose style="position: absolute; top: 0; right: 0">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center " class="main-content">
    <p class="mat-dialog-font">{{ 'shared.deactivate-dialog.sure-cancel' | translate }}</p>
  </div>

  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="onNoButtonClick()" cdkFocusInitial style="text-align: center">
      {{ ('shared.deactivate-dialog.no-button' | translate).toUpperCase() }}
    </button>
    <button mat-button [matDialogClose]="true" class="btn-color" style="text-align: center">
      {{ ('shared.deactivate-dialog.yes-button' | translate).toUpperCase() }}
    </button>
  </div>
</div>
