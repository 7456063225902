<section class="container">
  <div class="header-manager-company">
    <h1 style="font-size: 20px !important" *ngIf="typeCompany === companyTypeEnum.IMPORTER">
      {{ 'settings.companies.importer' | translate }}
    </h1>
    <h1 style="font-size: 20px !important" *ngIf="typeCompany === companyTypeEnum.BUYER">
      {{ 'easy-trade.new-trade.stepper.companies-involved.buyer' | translate }}
    </h1>
    <h1 style="font-size: 20px !important" *ngIf="typeCompany === companyTypeEnum.CONSIGNEE">
      {{ 'easy-trade.new-trade.stepper.companies-involved.consignee' | translate }}
    </h1>
    <div>
      <mat-icon *ngIf="typeCompany !== companyTypeEnum.IMPORTER" (click)="closeTable()"> close </mat-icon>
    </div>
  </div>
  <div class="container-header">
    <mat-form-field appearance="outline" color="accent">
      <input
        class="search-company"
        type="text"
        (keydown)="onKeyDown($event)"
        [placeholder]="'settings.company-management.search' | translate"
        matInput
        [formControl]="inputSearch"
        autocomplete="off"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <button *ngIf="modules.includes(modulePermission.SolicitarNovaEmpresa)" mat-flat-button color="accent" class="button-new-company" (click)="openDialogNewCompany()">
      <mat-icon> add </mat-icon>
      {{ ('settings.company-management.new-company' | translate).toUpperCase() }}
    </button>
  </div>
  <div>
    <div fxLayout="row wrap" class="search-area" fxLayoutAlign="space-between start"></div>
    <div *ngIf="modules.includes(modulePermission.VisualizarEmpresas)" class="table">
      <table mat-table [dataSource]="dataSource" matSort #empTbSort="matSort" class="example-table table unset-color">
        <ng-container matColumnDef="select">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settings.companies.select' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="padding-right">
            <mat-radio-group>
              <mat-radio-button [checked]="radioStatus" (change)="radioChange($event)" class="example-margin" [value]="element"></mat-radio-button>
            </mat-radio-group>
          </td>
        </ng-container>
        <ng-container matColumnDef="favorite">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'settings.companies.favorite' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="padding-right">
            <mat-icon (click)="favoriteCompany(element, false)" class="start" *ngIf="element.favorite"> star </mat-icon>
            <mat-icon (click)="favoriteCompany(element, true)" class="not-favorite start" *ngIf="!element.favorite"> star </mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>#ID</th>
          <td mat-cell *matCellDef="let element" class="padding-right">
            {{ element.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settings.companies.company-name' | translate }}
          </th>
          <td mat-cell matTooltip="{{ element.name }}" *matCellDef="let element" class="padding-right">
            {{ element.name | truncate : 30 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settings.companies.street' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="padding-right">
            {{ element?.street | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="idCountry">
          <th class="default-size" style="width: 10%" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settings.companies.country' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="padding-right">
            <div class="d-flex justify-content-start align-items-center">
              <img class="custom-flag" [src]="'assets/svg-country-flags/svg/' + element?.alpha2Code + '.svg'" alt="Bandeira do país de destino" />
              <p style="margin-top: 12%; margin-left: 8px">{{ element.country.name }}</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="poBox">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settings.companies.poBox' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="padding-right">
            {{ element?.poBox | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="default-size" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settings.companies.actions' | translate }}
          </th>

          <td mat-cell *matCellDef="let element">
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon> more_vert </mat-icon></button>
            <mat-menu #menu="matMenu">
              <div class="container-actions">
                <button class="actions-btn" (click)="selectCompany(element)" mat-icon-button>
                  <mat-icon> done </mat-icon>
                  <span>{{ 'settings.companies.select' | translate }}</span>
                </button>
                <button class="actions-btn" (click)="openDialogNewCompany(element)" mat-icon-button>
                  <mat-icon> create </mat-icon>
                  <span>{{ 'settings.companies.edit' | translate }}</span>
                </button>
                <button class="actions-btn" (click)="removeCompany(element)" mat-icon-button>
                  <mat-icon> delete </mat-icon>
                  <span>{{ 'settings.companies.delete' | translate }}</span>
                </button>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        class="unset-color"
        #paginator
        [length]="count"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [pageIndex]="pageIndex"
        [pageSize]="query?.PageSize"
        showFirstLastButtons
        (page)="filterPage($event)"
      >
      </mat-paginator>
    </div>
  </div>
</section>
