import { FormControl } from '@angular/forms';
import { Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Transshipment } from '../../models/transshipment.interface';
import { TransshipmentResponse } from '../../models/transshipment-reponse.interface';

import { Subject, Observable, from } from 'rxjs';
import { debounceTime, switchMap, takeUntil, startWith } from 'rxjs/operators';

import { CertificateProcessingEnum, TransshipmentMethodEnum } from 'src/app/modules/easy-trade/enums/new-trade.enum';

import { ExportationService } from 'src/app/modules/easy-trade/services/exportation.service';
@Component({
  selector: 'app-port-airport-autocomplete-native',
  templateUrl: './port-airport-autocomplete-native.component.html',
  styleUrls: ['./port-airport-autocomplete-native.component.scss']
})
export class PortAirportAutocompleteNativeComponent implements OnInit, OnChanges {
  destroy$ = new Subject();
  transshipments$: Observable<Transshipment[]>;

  loading: boolean;
  inputSearch = new FormControl();
  transshipments: Transshipment[] = [];
  transshipMethods = TransshipmentMethodEnum;
  private selectedTransshipment: Transshipment;

  @Input() isSummary: boolean;
  @Input() label: string;
  @Input() isNewTrade: boolean;
  @Input() required: boolean = false;
  @Input() hiddenFields: number[] = [];
  @Input() readonlyField: boolean = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() defaultTransshipment: Transshipment = undefined;
  @Output() validateTransshipment$ = new EventEmitter<number>(undefined);
  @Output() selectedTransshipment$ = new EventEmitter<Transshipment>(undefined);
  @Input() reset: boolean = false;
  @Output() resetEvent$ = new EventEmitter<any>(undefined);
  @Input() transshipmentMethod: TransshipmentMethodEnum;
  @Input() CertificateProcessing: CertificateProcessingEnum;
  autocompletePanelOpen = false;
  @Input() urlExportationService?: string;
  @ViewChild('auto', { read: MatAutocompleteTrigger }) autocomplete: MatAutocompleteTrigger;

  constructor(private readonly exportationService: ExportationService) {}

  async ngOnInit(): Promise<any> {
    if (this.defaultTransshipment != undefined) {
      this.setDefault();
    }

    this.transshipments$ = this.inputSearch.valueChanges.pipe(
      debounceTime(400),
      takeUntil(this.destroy$),
      startWith(''),
      switchMap(async (value: any) => {
        if (!value?.id) {
          this.checkValueOfinputSearch();
          return await this.getTransshipments(typeof value === 'string' ? value : '');
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const reset = changes['reset'];
    if (reset?.currentValue === true) {
      this.clearField();
      this.reset = false;
    }
  }

  setDefault() {
    this.inputSearch.setValue(this.defaultTransshipment);
    this.inputSearch.updateValueAndValidity();
  }

  clearField() {
    this.transshipments = [];
    this.selectedTransshipment = undefined;
    // Resetando o FormControl explicitamente
    this.inputSearch.reset();
    this.searchInput.nativeElement.value = '';
    this.inputSearch.updateValueAndValidity();
  }

  displayFn(transship: Transshipment): string {
    if (transship.name && transship.code) {
      return transship.name + ' (' + transship.code + ')';
    }
    return '';
  }

  checkValueOfinputSearch() {
    if (this.selectedTransshipment) {
      this.validateTransshipment$.emit(this.selectedTransshipment?.id);
    }
  }

  setTransship(transship) {
    if (!this.readonlyField) {
      this.selectedTransshipment$.emit(transship);
      this.selectedTransshipment = transship;
      this.autocompletePanelOpen = false; // Feche o painel do autocompletar após a seleção
      this.searchInput.nativeElement.value = transship?.name;
    }
  }

  async getTransshipments(transshipmentMethodValueSearch: string) {
    if (!this.readonlyField) {
      try {
        let response: TransshipmentResponse;
        if (this.transshipmentMethod == TransshipmentMethodEnum.plane) {
          response = await this.exportationService.getAirports({
            PageIndex: 0,
            PageSize: 10,
            Name: transshipmentMethodValueSearch.trim()
          });
        } else if (this.transshipmentMethod == TransshipmentMethodEnum.ship) {
          response = await this.exportationService.getSeaPorts({
            PageIndex: 0,
            PageSize: 10,
            Name: transshipmentMethodValueSearch.trim(),
            LoadSeaPortsForCertificateProcessing: this.CertificateProcessing
          });
        }
        return response ? response.items : [];
      } catch (e) {
        return (this.transshipments = []);
      }
    }
    return undefined;
  }

  shouldShow(transship: Transshipment) {
    if (transship.id !== -1) {
      return !this.hiddenFields.includes(transship.id);
    }
    return undefined;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
