<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span></span>
      <p>{{ 'shared.request-payment.title' | translate }}</p>
      <button mat-icon-button matDialogClose>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
  </div>

  <p class="mat-h3" color="primary">{{ 'shared.request-payment.label' | translate }}</p>
  <p class="valor-pagar">R${{ amount }}</p>

  <div>
    <p class="mat-h3" color="primary">{{ 'shared.request-payment.label1' | translate }}</p>
    <div class="qrcode-container" style="text-align: center">
      <div class="rounded-qrcode">
        <qrcode [qrdata]="'Certificado Zeferino'" [width]="180" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </div>

    <p class="mat-h3" color="primary">{{ 'shared.request-payment.label2' | translate }}</p>
    <mat-card class="pix-card" color="primary">
      <mat-card-header></mat-card-header>
      <mat-card-content color="primary">
        <div class="pix-content">
          <p class="break-text">{{ pixCopyPaste }}</p>
          <mat-icon class="copy-icon" (click)="copyPix()">content_copy</mat-icon>
        </div>
      </mat-card-content>
    </mat-card>
    <div>
      <div mat-dialog-actions class="dialog-content" fxLayout="column" fxLayoutAlign="center center">
        <mat-divider class="divider" style="margin: 30px; width: 610px"></mat-divider>
        <button mat-button [matDialogClose]="true" class="btn-color" style="text-align: center">
          {{ ('shared.request-payment.check-payment-button' | translate).toUpperCase() }}
        </button>
      </div>
    </div>
  </div>
</div>
