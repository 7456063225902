import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-request-aproval-dialog',
  templateUrl: './request-aproval-dialog.component.html',
  styleUrls: ['./request-aproval-dialog.component.scss']
})
export class RequestAprovalDialogComponent implements OnInit {
  constructor(private readonly router: Router) {}
  ngOnInit(): void {}

  RedirectToMonitoring(): void {
    this.router.navigate(['/Certificates/Certificateorigin'], { queryParams: { refresh: new Date().getTime() } });
  }
}
